const PrivacyPage = () => {
  return (
    <div>
      <strong style={{ fontSize: "26px" }}>
        <span>PRIVACY NOTICE</span>
      </strong>
      <div style={{ color: "rgb(127, 127, 127)", marginTop: "10px" }}>
        <strong style={{ fontSize: "15px" }}>
          Last updated <span>December 17, 2023</span>
        </strong>
      </div>
      <div style={{ lineHeight: "1.5", marginTop: "20px" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
          This privacy notice for <span>__________</span> (&quot;
          <strong>we</strong>
          ,&quot; &quot;<strong>us</strong>,&quot; or &quot;<strong>our</strong>
          &quot;), describes how and why we might collect, store, use, and/or
          share (&quot;
          <strong>process</strong>&quot;) your information when you use our
          services (&quot;<strong>Services</strong>&quot;), such as when you:
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
            fontSize: "15px",
            color: "rgb(89, 89, 89)",
          }}
        >
          Visit our website at{" "}
          <a href="https://wander.one" style={{ color: "rgb(0, 58, 250)" }}>
            wander.one
          </a>
          , or any website of ours that links to this privacy notice
        </li>
        <li
          style={{
            lineHeight: "1.5",
            fontSize: "15px",
            color: "rgb(89, 89, 89)",
          }}
        >
          Download and use our mobile application (<span>Wander One</span>), or
          any other application of ours that links to this privacy notice
        </li>
        <li
          style={{
            lineHeight: "1.5",
            fontSize: "15px",
            color: "rgb(89, 89, 89)",
          }}
        >
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(127, 127, 127)",
        }}
      >
        <strong>Questions or concerns?</strong> Reading this privacy notice will
        help you understand your privacy rights and choices. If you do not agree
        with our policies and practices, please do not use our Services.
      </div>
      <div style={{ lineHeight: "1.5", marginTop: "20px" }}>
        <strong style={{ fontSize: "15px" }}>SUMMARY OF KEY POINTS</strong>
      </div>
      <div style={{ lineHeight: "1.5", fontSize: "15px" }}>
        <span>
          <strong>
            <em>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our{" "}
            </em>
          </strong>
        </span>
        <a href="#toc" style={{ color: "rgb(0, 58, 250)" }}>
          <strong>
            <em>table of contents</em>
          </strong>
        </a>
        <span>
          <strong>
            <em> below to find the section you are looking for.</em>
          </strong>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(127, 127, 127)",
        }}
      >
        <strong>What personal information do we process?</strong> When you
        visit, use, or navigate our Services, we may process personal
        information depending on how you interact with us and the Services, the
        choices you make, and the products and features you use. Learn more
        about{" "}
        <a href="#personalinfo" style={{ color: "rgb(0, 58, 250)" }}>
          personal information you disclose to us
        </a>
        .
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(127, 127, 127)",
        }}
      >
        <strong>Do we process any sensitive personal information?</strong> We do
        not process sensitive personal information.
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(127, 127, 127)",
        }}
      >
        <strong>Do we receive any information from third parties?</strong> We
        may receive information from public databases, marketing partners,
        social media platforms, and other outside sources. Learn more about{" "}
        <a href="#othersources" style={{ color: "rgb(0, 58, 250)" }}>
          information collected from other sources
        </a>
        .
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(127, 127, 127)",
        }}
      >
        <strong>How do we process your information?</strong> We process your
        information to provide, improve, and administer our Services,
        communicate with you, for security and fraud prevention, and to comply
        with law. We may also process your information for other purposes with
        your consent. We process your information only when we have a valid
        legal reason to do so. Learn more about{" "}
        <a href="#infouse" style={{ color: "rgb(0, 58, 250)" }}>
          how we process your information
        </a>
        .
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(127, 127, 127)",
        }}
      >
        <strong>
          In what situations and with which parties do we share personal
          information?
        </strong>{" "}
        We may share information in specific situations and with specific third
        parties. Learn more about{" "}
        <a href="#whoshare" style={{ color: "rgb(0, 58, 250)" }}>
          when and with whom we share your personal information
        </a>
        .
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(127, 127, 127)",
        }}
      >
        <strong>What are your rights?</strong> Depending on where you are
        located geographically, the applicable privacy law may mean you have
        certain rights regarding your personal information. Learn more about{" "}
        <a href="#privacyrights" style={{ color: "rgb(0, 58, 250)" }}>
          your privacy rights
        </a>
        .
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(127, 127, 127)",
        }}
      >
        <strong>How do you exercise your rights?</strong> The easiest way to
        exercise your rights is by{" "}
        <a
          href="https://app.termly.io/notify/400df638-3baa-4b29-90dc-e68154f7ecbc"
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: "rgb(0, 58, 250)" }}
        >
          data subject access request
        </a>
        , or by contacting us. We will consider and act upon any request in
        accordance with applicable data protection laws.
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(127, 127, 127)",
        }}
      >
        Want to learn more about what we do with any information we collect?{" "}
        <a href="#toc" style={{ color: "rgb(0, 58, 250)" }}>
          Review the privacy notice in full
        </a>
        .
      </div>

      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="toc" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(127, 127, 127)" }}>
          <strong style={{ color: "rgb(0, 0, 0)" }}>TABLE OF CONTENTS</strong>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#infocollect" style={{ color: "rgb(0, 58, 250)" }}>
            1. WHAT INFORMATION DO WE COLLECT?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#infouse" style={{ color: "rgb(0, 58, 250)" }}>
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#legalbases" style={{ color: "rgb(0, 58, 250)" }}>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#whoshare" style={{ color: "rgb(0, 58, 250)" }}>
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#cookies" style={{ color: "rgb(0, 58, 250)" }}>
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#sociallogins" style={{ color: "rgb(0, 58, 250)" }}>
            6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#intltransfers" style={{ color: "rgb(0, 58, 250)" }}>
            7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#inforetain" style={{ color: "rgb(0, 58, 250)" }}>
            8. HOW LONG DO WE KEEP YOUR INFORMATION?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#privacyrights" style={{ color: "rgb(0, 58, 250)" }}>
            9. WHAT ARE YOUR PRIVACY RIGHTS?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#DNT" style={{ color: "rgb(0, 58, 250)" }}>
            10. CONTROLS FOR DO-NOT-TRACK FEATURES
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#uslaws" style={{ color: "rgb(0, 58, 250)" }}>
            11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#policyupdates" style={{ color: "rgb(0, 58, 250)" }}>
            12. DO WE MAKE UPDATES TO THIS NOTICE?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#contact" style={{ color: "rgb(0, 58, 250)" }}>
            13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a href="#request" style={{ color: "rgb(0, 58, 250)" }}>
            14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="infocollect" style={{ lineHeight: "1.5" }}></div>

      <div id="control" style={{ color: "rgb(0, 0, 0)", lineHeight: "1.5" }}>
        <strong>
          <span>1. WHAT INFORMATION DO WE COLLECT?</span>
        </strong>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="personalinfo" style={{ lineHeight: "1.5" }}>
        <strong style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
          Personal information you disclose to us
        </strong>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
          color: "rgb(127, 127, 127)",
          fontSize: "15px",
        }}
      >
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <strong>
            <em>In Short:</em>
          </strong>{" "}
          <em>We collect personal information that you provide to us.</em>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        <strong>Personal Information Provided by You.</strong> The personal
        information that we collect depends on the context of your interactions
        with us and the Services, the choices you make, and the products and
        features you use. The personal information we collect may include the
        following:
      </div>
      <ul
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        <li>names</li>
        <li>email addresses</li>
        <li>phone numbers</li>
        <li>usernames</li>
        <li>passwords</li>
        <li>contact preferences</li>
        <li>mailing addresses</li>
        <li>job titles</li>
        <li>contact or authentication data</li>
        <li>billing addresses</li>
        <li>debit/credit card numbers</li>
      </ul>
      <div
        id="sensitiveinfo"
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        <strong>Sensitive Information.</strong> We do not process sensitive
        information.
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        <strong>Payment Data.</strong> We may collect data necessary to process
        your payment if you make purchases, such as your payment instrument
        number, and the security code associated with your payment instrument.
        All payment data is stored by{" "}
        <span style={{ color: "rgb(0, 58, 250)" }}>__________</span>. You may
        find their privacy notice link(s) here:{" "}
        <span style={{ color: "rgb(0, 58, 250)" }}>__________</span>.
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        <strong>Social Media Login Data.</strong> We may provide you with the
        option to register with us using your existing social media account
        details, like your Facebook, Twitter, or other social media account. If
        you choose to register in this way, we will collect the information
        described in the section called{" "}
        <a href="#sociallogins" style={{ color: "rgb(0, 58, 250)" }}>
          HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </a>{" "}
        below.
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        <strong>Application Data.</strong> If you use our application(s), we
        also may collect the following information if you choose to provide us
        with access or permission:
      </div>
      <ul
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        <li>
          <em>Geolocation Information.</em> We may request access or permission
          to track location-based information from your mobile device, either
          continuously or while you are using our mobile application(s), to
          provide certain location-based services. If you wish to change our
          access or permissions, you may do so in your device's settings.
        </li>
        <li>
          <em>Mobile Device Access.</em> We may request access or permission to
          certain features from your mobile device, including your mobile
          device's camera, contacts, social media accounts, and other features.
          If you wish to change our access or permissions, you may do so in your
          device's settings.
        </li>
        <li>
          <em>Mobile Device Data.</em> We automatically collect device
          information (such as your mobile device ID, model, and manufacturer),
          operating system, version information and system configuration
          information, device and application identification numbers, browser
          type and version, hardware model Internet service provider and/or
          mobile carrier, and Internet Protocol (IP) address (or proxy server).
          If you are using our application(s), we may also collect information
          about the phone network associated with your mobile device, your
          mobile device’s operating system or platform, the type of mobile
          device you use, your mobile device’s unique device ID, and information
          about the features of our application(s) you accessed.
        </li>
        <li>
          <em>Push Notifications.</em> We may request to send you push
          notifications regarding your account or certain features of the
          application(s). If you wish to opt out from receiving these types of
          communications, you may turn them off in your device's settings.
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        This information is primarily needed to maintain the security and
        operation of our application(s), for troubleshooting, and for our
        internal analytics and reporting purposes.
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
          fontSize: "15px",
          color: "rgb(89, 89, 89)",
        }}
      >
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>

      <div>
        <div style={{ lineHeight: "1.5" }}>
          <span className="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
            <span style={{ fontSize: "15px" }}>
              <strong>Information automatically collected</strong>
            </span>
          </span>
        </div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
              <span className="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span className="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
                <span className="body_text">
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span className="body_text">
                        <strong>
                          <em></em>
                        </strong>
                        <em>
                          Some information — such as your Internet Protocol (IP)
                          address and/or browser and device characteristics — is
                          collected automatically when you visit our Services.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text">
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text">
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text">
                The information we collect includes:
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <em>Log and Usage Data.</em> Log and usage data is
                  service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access
                  or use our Services and which we record in log files.
                  Depending on how you interact with us, this log data may
                  include your IP address, device information, browser type, and
                  settings and information about your activity in the Services
                  (such as the date/time stamps associated with your usage,
                  pages and files viewed, searches, and other actions you take
                  such as which features you use), device event information
                  (such as system activity, error reports (sometimes called
                  "crash dumps"), and hardware settings).
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <em>Device Data.</em> We collect device data such as
                  information about your computer, phone, tablet, or other
                  device you use to access the Services. Depending on the device
                  used, this device data may include information such as your IP
                  address (or proxy server), device and application
                  identification numbers, location, browser type, hardware
                  model, Internet service provider and/or mobile carrier,
                  operating system, and system configuration information.
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <em>Location Data.</em> We collect location data such as
                  information about your device's location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the
                  Services. For example, we may use GPS and other technologies
                  to collect geolocation data that tells us your current
                  location (based on your IP address). You can opt out of
                  allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. However, if you choose to opt out, you
                  may not be able to use certain aspects of the Services.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div id="othersources" style={{ lineHeight: "1.5" }}>
          <span className="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
            <span style={{ fontSize: "15px" }}>
              <strong>Information collected from other sources</strong>
            </span>
          </span>
        </div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
              <span className="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span className="body_text">
                      <strong>
                        <em>In Short: </em>
                      </strong>
                      <em>
                        We may collect limited data from public databases,
                        marketing partners, social media platforms, and other
                        outside sources.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text">
                In order to enhance our ability to provide relevant marketing,
                offers, and services to you and update our records, we may
                obtain information about you from other sources, such as public
                databases, joint marketing partners, affiliate programs, data
                providers, social media platforms, and from other third parties.
                This information includes mailing addresses, job titles, email
                addresses, phone numbers, intent data (or user behavior data),
                Internet Protocol (IP) addresses, social media profiles, social
                media URLs, and custom profiles, for purposes of targeted
                advertising and event promotion. If you interact with us on a
                social media platform using your social media account (e.g.,
                Facebook or Twitter), we receive personal information about you
                such as your name, email address, and gender. Any personal
                information that we collect from your social media account
                depends on your social media account's privacy settings.
              </span>
            </span>
          </span>
        </div>
        <div id="infouse" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
              <span className="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span className="body_text">
                      <strong>
                        <em>In Short: </em>
                      </strong>
                      <em>
                        We process your information to provide, improve, and
                        administer our Services, communicate with you, for
                        security and fraud prevention, and to comply with law.
                        We may also process your information for other purposes
                        with your consent.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text">
                <strong>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </strong>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <strong>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.
                  </strong>{" "}
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <strong>
                    To deliver and facilitate delivery of services to the user.
                  </strong>{" "}
                  We may process your information to provide you with the
                  requested service.
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <strong>
                    To respond to user inquiries/offer support to users.
                  </strong>{" "}
                  We may process your information to respond to your inquiries
                  and solve any potential issues you might have with the
                  requested service.
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <strong>To send administrative information to you.</strong> We
                  may process your information to send you details about our
                  products and services, changes to our terms and policies, and
                  other similar information.
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <strong>To fulfill and manage your orders.</strong> We may
                  process your information to fulfill and manage your orders,
                  payments, returns, and exchanges made through the Services.
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <strong>To enable user-to-user communications.</strong> We may
                  process your information if you choose to use any of our
                  offerings that allow for communication with another user.
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <strong>To request feedback.</strong> We may process your
                  information when necessary to request feedback and to contact
                  you about your use of our Services.
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span className="body_text">
                  <strong>
                    To send you marketing and promotional communications.
                  </strong>{" "}
                  We may process the personal information you send to us for our
                  marketing purposes, if this is in accordance with your
                  marketing preferences. You can opt out of our marketing emails
                  at any time. For more information, see{" "}
                  <a href="#privacyrights" style={{ color: "rgb(0, 58, 250)" }}>
                    WHAT ARE YOUR PRIVACY RIGHTS?
                  </a>{" "}
                  below.
                </span>
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text">
                <strong>To deliver targeted advertising to you.</strong> We may
                process your information to develop and display personalized
                content and advertising tailored to your interests, location,
                and more.
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                <strong>To protect our Services.</strong> We may process your
                information as part of our efforts to keep our Services safe and
                secure, including fraud monitoring and prevention.
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                <strong>To identify usage trends.</strong> We may process
                information about how you use our Services to better understand
                how they are being used so we can improve them.
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                <strong>
                  To determine the effectiveness of our marketing and
                  promotional campaigns.
                </strong>{" "}
                We may process your information to better understand how to
                provide marketing and promotional campaigns that are most
                relevant to you.
              </span>
            </span>
          </li>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                <strong>
                  To save or protect an individual's vital interest.
                </strong>{" "}
                We may process your information when necessary to save or
                protect an individual’s vital interest, such as to prevent harm.
              </span>
            </span>
          </li>
        </ul>
      </div>

      <div id="legalbases" style={{ lineHeight: "1.5" }}>
        <strong>
          <span style={{ fontSize: "15px" }}>
            <span className="heading_1">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </span>
          </span>
        </strong>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <em>
            <span style={{ fontSize: "15px" }}>
              <span className="body_text">
                <strong>In Short: </strong>
                We only process your personal information when we believe it is
                necessary and we have a valid legal reason (i.e., legal basis)
                to do so under applicable law, like with your consent, to comply
                with laws, to provide you with services to enter into or fulfill
                our contractual obligations, to protect your rights, or to
                fulfill our legitimate business interests.
              </span>
            </span>
          </em>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text"></span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <em>
            <span style={{ fontSize: "15px" }}>
              <span className="body_text">
                <strong>
                  <u>
                    If you are located in the EU or UK, this section applies to
                    you.
                  </u>
                </strong>
              </span>
            </span>
          </em>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text"></span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text">
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px" }}>
              <span className="body_text">
                <strong>Consent. </strong>
                We may process your information if you have given us permission
                (i.e., consent) to use your personal information for a specific
                purpose. You can withdraw your consent at any time. Learn more
                about{" "}
                <a
                  className="link"
                  href="#withdrawconsent"
                  style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}
                >
                  withdrawing your consent
                </a>
                .
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text"></span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                <strong>Performance of a Contract.</strong> We may process your
                personal information when we believe it is necessary to fulfill
                our contractual obligations to you, including providing our
                Services or at your request prior to entering into a contract
                with you.
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                <strong>Legitimate Interests.</strong> We may process your
                information when we believe it is reasonably necessary to
                achieve our legitimate business interests and those interests do
                not outweigh your interests and fundamental rights and freedoms.
                For example, we may process your personal information for some
                of the purposes described in order to:
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul style={{ marginLeft: "40px" }}>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                Send users information about special offers and discounts on our
                products and services
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul style={{ marginLeft: "40px" }}>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                Develop and display personalized and relevant advertising
                content for our users
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul style={{ marginLeft: "40px" }}>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                Analyze how our Services are used so we can improve them to
                engage and retain users
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul style={{ marginLeft: "40px" }}>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                Support our marketing activities
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul style={{ marginLeft: "40px" }}>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                Diagnose problems and/or prevent fraudulent activities
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul style={{ marginLeft: "40px" }}>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                Understand how our users use our products and services so we can
                improve user experience
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                <strong>Legal Obligations.</strong> We may process your
                information where we believe it is necessary for compliance with
                our legal obligations, such as to cooperate with a law
                enforcement body or regulatory agency, exercise or defend our
                legal rights, or disclose your information as evidence in
                litigation in which we are involved.
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                <strong>Vital Interests.</strong> We may process your
                information where we believe it is necessary to protect your
                vital interests or the vital interests of a third party, such as
                situations involving potential threats to the safety of any
                person.
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <strong>
                <u>
                  <em>
                    If you are located in Canada, this section applies to you.
                  </em>
                </u>
              </strong>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can{" "}
            </span>
          </span>
          <a className="link" href="#withdrawconsent">
            <span className="body_text">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                withdraw your consent
              </span>
            </span>
          </a>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}> at any time.</span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                For investigations and fraud detection and prevention
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                For business transactions provided certain conditions are met
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                If we have reasonable grounds to believe an individual has been,
                is, or may be victim of financial abuse
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}></span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px" }}>
              <span className="body_text">
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px" }}>
              <span className="body_text">
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span className="body_text">
              <span style={{ fontSize: "15px" }}>
                If the information is publicly available and is specified by the
                regulations
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="whoshare" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span className="heading_1">
                        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                        INFORMATION?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text">
                <strong>
                  <em>In Short:</em>
                </strong>
                <em>
                  {" "}
                  We may share information in specific situations described in
                  this section and/or with the following third parties.
                </em>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text">
              <strong>
                Vendors, Consultants, and Other Third-Party Service Providers.
              </strong>{" "}
              We may share your data with third-party vendors, service
              providers, contractors, or agents ("third parties") who perform
              services for us or on our behalf and require access to such
              information to do that work.
            </span>
          </span>
        </div>
      </div>
      <div id="legalbases" style={{ lineHeight: "1.5" }}>
        <strong>
          <span style={{ fontSize: "15px" }}>
            <span className="heading_1">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </span>
          </span>
        </strong>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <em>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text">
              <strong>In Short: </strong>We only process your personal
              information when we believe it is necessary and we have a valid
              legal reason (i.e., legal basis) to do so under applicable law,
              like with your consent, to comply with laws, to provide you with
              services to enter into or fulfill our contractual obligations, to
              protect your rights, or to fulfill our legitimate business
              interests.
            </span>
          </span>
        </em>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text"></span>
          <span className="body_text"></span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <em>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text">
              <strong>
                <u>
                  If you are located in the EU or UK, this section applies to
                  you.
                </u>
              </strong>
            </span>
          </span>
        </em>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text"></span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text">
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text">
              <strong>Consent. </strong>We may process your information if you
              have given us permission (i.e., consent) to use your personal
              information for a specific purpose. You can withdraw your consent
              at any time. Learn more about{" "}
            </span>
          </span>
          <a className="link" href="#withdrawconsent">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
              <span className="body_text">withdrawing your consent</span>
            </span>
          </a>
          <span className="body_text">.</span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text"></span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <strong>Performance of a Contract.</strong> We may process your
              personal information when we believe it is necessary to fulfill
              our contractual obligations to you, including providing our
              Services or at your request prior to entering into a contract with
              you.
            </span>
          </span>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text"></span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text"></span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <strong>Legitimate Interests.</strong> We may process your
              information when we believe it is reasonably necessary to achieve
              our legitimate business interests and those interests do not
              outweigh your interests and fundamental rights and freedoms. For
              example, we may process your personal information for some of the
              purposes described in order to:
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <ul style={{ marginLeft: "40px" }}>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              Send users information about special offers and discounts on our
              products and services
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <ul style={{ marginLeft: "40px" }}>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              Develop and display personalized and relevant advertising content
              for our users
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <ul style={{ marginLeft: "40px" }}>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              Analyze how our Services are used so we can improve them to engage
              and retain users
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <ul style={{ marginLeft: "40px" }}>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              Support our marketing activities
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <ul style={{ marginLeft: "40px" }}>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              Diagnose problems and/or prevent fraudulent activities
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <ul style={{ marginLeft: "40px" }}>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              Understand how our users use our products and services so we can
              improve user experience
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text"></span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <strong>Legal Obligations.</strong> We may process your
              information where we believe it is necessary for compliance with
              our legal obligations, such as to cooperate with a law enforcement
              body or regulatory agency, exercise or defend our legal rights, or
              disclose your information as evidence in litigation in which we
              are involved.
            </span>
            <br />
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <strong>Vital Interests.</strong> We may process your information
              where we believe it is necessary to protect your vital interests
              or the vital interests of a third party, such as situations
              involving potential threats to the safety of any person.
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="whoshare" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span className="heading_1">
                      4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                      INFORMATION?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                {" "}
                We may share information in specific situations described in
                this section and/or with the following third parties.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text"></span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text">
            <strong>
              Vendors, Consultants, and Other Third-Party Service Providers.
            </strong>{" "}
            We may share your data with third-party vendors, service providers,
            contractors, or agents ("third parties") who perform services for us
            or on our behalf and require access to such information to do that
            work. The third parties we may share personal information with are
            as follows:
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text"></span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <bdt className="block-component"></bdt>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text"></span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <bdt className="block-component">
            <span className="body_text"></span>
          </bdt>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <span className="body_text">
              <strong>Affiliates. </strong>We may share your information with
              our affiliates, in which case we will require those affiliates to
              honor this privacy notice. Affiliates include our parent company
              and any subsidiaries, joint venture partners, or other companies
              that we control or that are under common control with us.
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <bdt className="block-component">
          <span style={{ fontSize: "15px" }}>
            <span className="body_text"></span>
          </span>
        </bdt>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <strong>Business Partners.</strong> We may share your information
              with our business partners to offer you certain products,
              services, or promotions.
            </span>
          </span>
          <bdt className="statement-end-if-in-editor">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </bdt>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <bdt className="block-component">
          <span style={{ fontSize: "15px" }}>
            <span className="body_text"></span>
          </span>
        </bdt>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              <strong>Offer Wall. </strong>Our application(s) may display a
              third-party hosted "offer wall." Such an offer wall allows
              third-party advertisers to offer virtual currency, gifts, or other
              items to users in return for the acceptance and completion of an
              advertisement offer. Such an offer wall may appear in our
              application(s) and be displayed to you based on certain data, such
              as your geographic area or demographic information. When you click
              on an offer wall, you will be brought to an external website
              belonging to other persons and will leave our application(s). A
              unique identifier, such as your user ID, will be shared with the
              offer wall provider in order to prevent fraud and properly credit
              your account with the relevant reward.
            </span>
          </span>
          <bdt className="statement-end-if-in-editor">
            <span style={{ fontSize: "15px" }}>
              <span className="body_text"></span>
            </span>
          </bdt>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <bdt className="block-component">
          <span style={{ fontSize: "15px" }}>
            <span className="body_text"></span>
          </span>
        </bdt>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component">
                    <span className="heading_1"></span>
                  </bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="cookies" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span className="heading_1">
                      5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                {" "}
                We may use cookies and other tracking technologies to collect
                and store your information.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span className="body_text"></span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span className="body_text"></span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="sociallogins" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span className="heading_1">
                      6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                If you choose to register or log in to our Services using a
                social media account, we may have access to certain information
                about you.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span className="body_text"></span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span
                      style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}
                    >
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span className="body_text">
                                <bdt className="statement-end-if-in-editor"></bdt>
                              </span>
                              <bdt className="block-component">
                                <span className="body_text">
                                  <bdt className="block-component"></bdt>
                                </span>
                              </bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="intltransfers" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span className="heading_1">
                      7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                We may transfer, store, and process your information in
                countries other than your own.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div className="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              Our servers are located in
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span className="body_text"></span>
                </span>
              </span>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span className="body_text">
                    . If you are accessing our Services from outside
                  </span>
                  <span className="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span className="body_text"></span>
                      </span>
                    </span>
                  </span>
                  <span className="body_text">
                    , please be aware that your information may be transferred
                    to, stored, and processed by us in our facilities and by
                    those third parties with whom we may share your personal
                    information (see
                  </span>
                </span>
              </span>
              <a className="link" href="#whoshare">
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(0, 58, 250)" }}>
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span className="body_text"></span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span className="body_text"></span>
                </span>
              </span>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span className="body_text"> and other countries.</span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              If you are a resident in the European Economic Area (EEA), United
              Kingdom (UK), or Switzerland, then these countries may not
              necessarily have data protection laws or other similar laws as
              comprehensive as those in your country. However, we will take all
              necessary measures to protect your personal information in
              accordance with this privacy notice and applicable law.
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span className="body_text"></span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span className="body_text"></span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span className="body_text"></span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span
                      style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}
                      >
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span className="body_text">
                                  <bdt className="statement-end-if-in-editor"></bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="inforetain" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span className="heading_1">
                      8. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                We keep your information for as long as necessary to fulfill the
                purposes outlined in this privacy notice unless otherwise
                required by law.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than .
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span className="body_text"></span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="privacyrights" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span className="heading_1">
                      9. WHAT ARE YOUR PRIVACY RIGHTS?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                {" "}
                In some regions, such as the European Economic Area (EEA),
                United Kingdom (UK), Switzerland, and Canada, you have rights
                that allow you greater access to and control over your personal
                information. You may review, change, or terminate your account
                at any time.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              In some regions (like the EEA, UK, Switzerland, and Canada), you
              have certain rights under applicable data protection laws. These
              may include the right (i) to request access and obtain a copy of
              your personal information, (ii) to request rectification or
              erasure; (iii) to restrict the processing of your personal
              information; (iv) if applicable, to data portability; and (v) not
              to be subject to automated decision-making. In certain
              circumstances, you may also have the right to object to the
              processing of your personal information. You can make such a
              request by contacting us by using the contact details provided in
              the section{" "}
              <a className="link" href="#contact">
                <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </a>{" "}
              below.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}> </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your{" "}
              <a
                className="link"
                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                  Member State data protection authority
                </span>
              </a>{" "}
              or{" "}
              <a
                className="link"
                href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                  UK data protection authority
                </span>
              </a>
              .
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              If you are located in Switzerland, you may contact the{" "}
              <a
                className="link"
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span
                  style={{
                    fontSize: "15px",
                    color: "rgb(0, 58, 250)",
                  }}
                >
                  Federal Data Protection and Information Commissioner
                </span>
              </a>
              .
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="withdrawconsent" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              <strong>
                <u>Withdrawing your consent:</u>
              </strong>{" "}
              If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending
              on the applicable law, you have the right to withdraw your consent
              at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the section{" "}
              <a className="link" href="#contact">
                <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </a>{" "}
              below.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text">
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text">
            <strong>
              <u>Opting out of marketing and promotional communications:</u>
            </strong>
            <strong>
              <u> </u>
            </strong>
            You can unsubscribe from our marketing and promotional
            communications at any time by clicking on the unsubscribe link in
            the emails that we send, replying "STOP" or "UNSUBSCRIBE" to the SMS
            messages that we send, or by contacting us using the details
            provided in the section{" "}
            <a className="link" href="#contact">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </a>{" "}
            below. You will then be removed from the marketing lists. However,
            we may still communicate with you — for example, to send you
            service-related messages that are necessary for the administration
            and use of your account, to respond to service requests, or for
            other non-marketing purposes.
          </span>
          <span className="body_text"></span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span className="heading_2">
            <strong>Account Information</strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              Log in to your account settings and update your user account.
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span className="body_text">
            <span style={{ fontSize: "15px" }}>
              Contact us using the contact information provided.
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span className="body_text">
          <span style={{ fontSize: "15px" }}></span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span className="body_text">
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="DNT" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span className="heading_1">
                      10. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span className="body_text">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="uslaws" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
                      RIGHTS?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>In Short: </em>
                </strong>
                <em>
                  If you are a resident of{" "}
                  <bdt className="forloop-component"></bdt>, you are granted
                  specific rights regarding access to your personal information.
                </em>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  What categories of personal information do we collect?
                </strong>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                We have collected the following categories of personal
                information in the past twelve (12) months:
              </span>
            </span>
          </span>
        </div>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td
              style={{
                width: "33.8274%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>Category</strong>
                </span>
              </span>
            </td>
            <td
              style={{
                width: "51.4385%",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
              }}
            >
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>Examples</strong>
                </span>
              </span>
            </td>
            <td
              style={{
                width: "14.9084%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
                textAlign: "center",
              }}
            >
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>Collected</strong>
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "33.8274%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">A. Identifiers</span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "51.4385%",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address, and account name
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "14.9084%",
                textAlign: "center",
                verticalAlign: "middle",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">NO</span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "33.8274%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    B. Protected classification characteristics under state or
                    federal law
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "51.4385%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Gender and date of birth
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "14.9084%",
                textAlign: "center",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">NO</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "33.8274%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    C. Commercial information
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "51.4385%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Transaction information, purchase history, financial
                    details, and payment information
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "14.9084%",
                textAlign: "center",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">NO</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "33.8274%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    D. Biometric information
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "51.4385%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Fingerprints and voiceprints
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "14.9084%",
                textAlign: "center",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">NO</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "33.8274%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    E. Internet or other similar network activity
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "51.4385%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Browsing history, search history, online behavior, interest
                    data, and interactions with our and other websites,
                    applications, systems, and advertisements
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "14.9084%",
                textAlign: "center",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">NO</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "33.8274%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">F. Geolocation data</span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "51.4385%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">Device location</span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "14.9084%",
                textAlign: "center",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">NO</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "33.8274%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    G. Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "51.4385%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "14.9084%",
                textAlign: "center",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">NO</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "33.8274%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    H. Professional or employment-related information
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "51.4385%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Business contact details in order to provide you our
                    Services at a business level or job title, work history, and
                    professional qualifications if you apply for a job with us
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                width: "14.9084%",
                textAlign: "center",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">NO</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
                width: "33.8274%",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    I. Education Information
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                borderRight: "1px solid black",
                borderTop: "1px solid black",
                width: "51.4385%",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Student records and directory information
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                textAlign: "center",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
                width: "14.9084%",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">NO</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderWidth: "1px",
                borderColor: "black",
                borderStyle: "solid",
                width: "33.8274%",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    J. Inferences drawn from collected personal information
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "51.4385%",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individual’s preferences and
                    characteristics
                  </span>
                </span>
              </div>
            </td>
            <td
              style={{
                textAlign: "center",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                width: "14.9084%",
              }}
            >
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">NO</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                lineHeight: "1.5",
              }}
            >
              <span data-custom-class="body_text">
                K. Sensitive personal Information
              </span>
            </td>
            <td
              style={{
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                lineHeight: "1.5",
              }}
            >
              <span data-custom-class="body_text"></span>
            </td>
            <td
              style={{
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              <div data-empty="true" style={{ textAlign: "center" }}>
                <br />
              </div>
              <div
                data-custom-class="body_text"
                data-empty="true"
                style={{ textAlign: "center", lineHeight: "1.5" }}
              >
                <span data-custom-class="body_text">NO</span>
              </div>
              <div data-empty="true" style={{ textAlign: "center" }}>
                <br />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text"></span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Receiving help through our customer support channels;
            </span>
          </span>
        </li>
      </ul>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Participation in customer surveys or contests; and
            </span>
          </span>
        </li>
      </ul>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>How do we use and share your personal information?</strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text" style={{ fontSize: "15px" }}>
          Learn about how we use your personal information in the section,
          <a data-custom-class="link" href="#infouse">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
              HOW DO WE PROCESS YOUR INFORMATION?
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>Will your information be shared with anyone else?</strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Learn more about how we disclose personal information in the
            section,
            <a data-custom-class="link" href="#whoshare">
              <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </span>
            </a>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text" style={{ fontSize: "15px" }}>
          We have not sold or shared any personal information to third parties
          for a business or commercial purpose in the preceding twelve (12)
          months.
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We have disclosed the following categories of personal information
            to third parties for a business or commercial purpose in the
            preceding twelve (12) months:
          </span>
        </span>
      </div>
      <ul>
        <li style={{ fontSize: "15px", lineHeight: "1.5" }}>
          <span data-custom-class="body_text">
            The categories of third parties to whom we disclosed personal
            information for a business or commercial purpose can be found under
            <a data-custom-class="link" href="#whoshare">
              <span style={{ color: "rgb(0, 58, 250)" }}>
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </span>
            </a>
            .
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Receiving help through our customer support channels;
            </span>
          </span>
        </li>
      </ul>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Participation in customer surveys or contests; and
            </span>
          </span>
        </li>
      </ul>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>How do we use and share your personal information?</strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text" style={{ fontSize: "15px" }}>
          Learn about how we use your personal information in the section,
          <a data-custom-class="link" href="#infouse">
            <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
              HOW DO WE PROCESS YOUR INFORMATION?
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>Will your information be shared with anyone else?</strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Learn more about how we disclose personal information in the
            section,
            <a data-custom-class="link" href="#whoshare">
              <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </span>
            </a>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text" style={{ fontSize: "15px" }}>
          We have not sold or shared any personal information to third parties
          for a business or commercial purpose in the preceding twelve (12)
          months.
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We have disclosed the following categories of personal information
            to third parties for a business or commercial purpose in the
            preceding twelve (12) months:
          </span>
        </span>
      </div>
      <ul>
        <li style={{ fontSize: "15px", lineHeight: "1.5" }}>
          <span data-custom-class="body_text">
            The categories of third parties to whom we disclosed personal
            information for a business or commercial purpose can be found under
            <a data-custom-class="link" href="#whoshare">
              <span style={{ color: "rgb(0, 58, 250)" }}>
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </span>
            </a>
            .
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="policyupdates" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      12. DO WE MAKE UPDATES TO THIS NOTICE?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <em>
              <strong>In Short: </strong>Yes, we will update this notice as
              necessary to stay compliant with relevant laws.
            </em>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="contact" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            If you have questions or comments about this notice, you may contact
            us by post at:
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span>__________</span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span>__________</span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span>__________</span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="request" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please fill out and submit a{" "}
            <a
              data-custom-class="link"
              href="https://app.termly.io/notify/400df638-3baa-4b29-90dc-e68154f7ecbc"
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}
            >
              data subject access request
            </a>
            .
          </span>
        </span>
      </div>
      <style>
        {`
    ul {
      list-style-type: square;
    }
    ul > li > ul {
      list-style-type: circle;
    }
    ul > li > ul > li > ul {
      list-style-type: square;
    }
    ol li {
      font-family: Arial;
    }
  `}
      </style>
      <div
        style={{
          color: "#595959",
          fontSize: "14px",
          fontFamily: "Arial",
          paddingTop: "16px",
        }}
      >
        This privacy policy was created using Termly's{" "}
        <a
          style={{ color: "rgb(0, 58, 250)" }}
          href="https://termly.io/products/privacy-policy-generator/"
        >
          Privacy Policy Generator
        </a>
        .
      </div>
    </div>
  );
};

export default PrivacyPage;
